import React, { useState, useEffect } from 'react'
import { ControlledEditor as Editor } from '@monaco-editor/react'
import { css } from '@emotion/css'
import Modal from './Modal/Modal'
import Loader from './Loader/Loader'
import Button from './Components/Button/Button'

const styles = {
  grid: css`
    display: grid;
    grid-template-rows: 0fr 1fr;
    grid-template-columns: 0fr 1fr;
    grid-column-gap: 2em;
    column-gap: 2em;
    row-gap: 1em;
    overflow: auto;
  `,
  label: css`
    white-space: nowrap;
  `,
  modalBody: css`
    padding: 1em;
  `,
  button: css`
    padding: 4px 12px;
    color: white;
    float: right; // Evil
  `,
}

const Label = ({ label = null }) => {
  return label != null ? <label className={styles.label}>{label}</label> : null
}

const Input = ({ disabled, id, label, onChange, value, width = '40ch' }) => {
  return (
    <>
      <Label htmlFor={id} label={label} />
      <input
        style={{ width }}
        onChange={(e) => onChange && onChange(e.target.value)}
        value={value}
      />
    </>
  )
}

const NumberInput = ({
  disabled,
  id,
  label,
  onChange,
  value,
  width = '40ch',
}) => {
  return (
    <>
      <Label htmlFor={id} label={label} />
      <input
        inputMode="numeric"
        type="number"
        min="30"
        style={{ width }}
        onChange={(e) => onChange && onChange(e.target.value)}
        value={value}
      />
    </>
  )
}

const CheckboxInput = ({
  disabled,
  id,
  label,
  onChange,
  value,
  width = '40ch',
}) => {
  return (
    <>
      <Label htmlFor={id} label={label} />
      <div>
        <input
          type="checkbox"
          onChange={(e) => onChange && onChange(e.target.checked)}
          checked={value}
        />
      </div>
    </>
  )
}

const EditorInput = ({
  disabled,
  id,
  label,
  onChange,
  value,
  width = '40ch',
}) => {
  return (
    <>
      <Label htmlFor={id} label={label} />
      <Editor
        disabled
        id={id}
        onChange={onChange}
        loading={<Loader />}
        value={value}
        height="400px"
        width="30vw"
        language="javascript"
        theme="dark"
      />
    </>
  )
}

const DropdownInput = ({
  id,
  label,
  onChange,
  options,
  value,
  width = '40ch',
}) => (
  <>
    <Label label={label} htmlFor={id} />
    <select
      onChange={(e) => onChange && onChange(e.target.value)}
      value={value}
      style={{ width }}
    >
      {options.map((option) => {
        const label = typeof option === 'object' ? option.label : option
        const value = typeof option === 'object' ? option.value : option

        return <option value={value}>{label}</option>
      })}
    </select>
  </>
)

const ActionEditor = ({ actionType, onChange, value }) => {
  switch (actionType) {
    case 'Basic Response':
      return (
        <Input
          label="Response"
          onChange={(v) => onChange({ response: v })}
          value={value.response ?? ''}
        />
      )
    case 'Counter':
      return (
        <>
          <Input
            label="Counter"
            onChange={(v) =>
              onChange({
                counter: v,
                perUser: value.perUser,
              })
            }
          />
          <CheckboxInput
            label="Per User"
            onChange={(v) =>
              onChange({
                counter: value.counter,
                perUser: v,
              })
            }
          />
        </>
      )
    case 'Fetch':
      return (
        <Input
          label="URL"
          onChange={(v) => onChange({ url: v })}
          value={value.url ?? ''}
        />
      )
    case 'Script':
      return (
        <EditorInput
          label="Script"
          onChange={(_, v) => {
            console.log(_, v)
            onChange({ script: v })
          }}
          value={value.script}
        />
      )
    default:
      throw new Error('Reeeee: ' + actionType)
  }
}

const EditorCommon = ({
  actionData,
  actionType,
  children,
  name,
  setActionData,
  setActionType,
  setName,
}) => (
  <>
    <Input label="Script Name" onChange={setName} value={name} />
    {children}
    <DropdownInput
      label="Action Type"
      options={['Basic Response', 'Counter', 'Fetch', 'Script']}
      onChange={setActionType}
      value={actionType}
    />
    <ActionEditor
      actionType={actionType}
      onChange={setActionData}
      value={actionData}
    />
  </>
)

const EditorModal = ({ isAdmin, onChange, onHide, script, visible }) => {
  const [name, setName] = useState('')
  const [cooldown, setCooldown] = useState(60)
  const [actionType, setActionType] = useState('Basic Response')
  const [actionData, setActionData] = useState({})
  const [patternType, setPatternType] = useState('Command')
  const [pattern, setPattern] = useState('')
  const [adminCommand, setAdminCommand] = useState(false)

  useEffect(() => {
    if (script == null && visible) {
      setName('')
      setCooldown(60)
      setPatternType('Command')
      setPattern('')
      setActionType('Basic Response')
      setActionData({})
      setAdminCommand(false)
    } else if (script != null) {
      setName(script.name ?? '')
      setCooldown(script.cooldown)
      setPatternType(script.patternType ?? 'Command')
      setPattern(script.pattern ?? '')
      setActionType(script.actionType ?? 'Basic Response')
      setActionData(script.actionData ?? {})
      setAdminCommand(Boolean(script.admin_command))
    }
  }, [script, visible])

  return (
    <Modal
      height="60vh"
      width="50vw"
      visible={visible}
      header={`${script?.id != null ? 'Creating' : 'Editing'} Script`}
      footer={
        <Button
          onClick={() =>
            onChange({
              ...script,
              name,
              cooldown,
              actionType,
              actionData,
              patternType,
              pattern,
              admin_command: adminCommand,
            })
          }
          className={styles.button}
          label="Save"
        ></Button>
      }
      onHide={onHide}
    >
      <div className={styles.grid}>
        <EditorCommon
          actionData={actionData}
          actionType={actionType}
          name={name}
          setActionData={setActionData}
          setActionType={setActionType}
          setName={setName}
        >
          {isAdmin ? (
            <CheckboxInput
              label="Admin Command"
              value={adminCommand}
              onChange={setAdminCommand}
            />
          ) : null}
          <NumberInput
            label="Cooldown (seconds)"
            onChange={setCooldown}
            value={cooldown}
          />
          <DropdownInput
            label="Pattern Type"
            options={['Command', 'Substring', 'Regex']}
            onChange={setPatternType}
            value={patternType}
          />
          <Input label="Pattern" onChange={setPattern} value={pattern} />
        </EditorCommon>
      </div>
    </Modal>
  )
}

export default EditorModal
