import logo from './logo.svg'
import './App.css'
import EditorModal from './ScriptModal'
import Layout from './Components/Layout/Layout'
import Table from './TableNext'
import IntervalModal from './IntervalModal'
import CounterModal from './CounterModal'
import ScriptModal from './ScriptModal'
import axios from 'axios'

import deleteIcon from './clear.svg'
import editIcon from './edit.svg'
import { useState, useEffect } from 'react'
import Button from './Components/Button/Button'
import { css } from '@emotion/css'
import AuthorizedUsersModal from './AuthorizedUsersModal'

const styles = {
  button: css`
    margin-top: 4px;
    float: right;
  `,
}

const CommonTableSpecStart = [
  {
    name: 'name',
    label: 'Name',
    selector: 'name',
    rawSelector: 'name',
    type: 'text',
    width: 200,
    style: { textAlign: 'left' },
  },
]

const CommonTableSpecEnd = (
  channelName,
  onEdit,
  onDelete,
  type,
  isAdmin,
  isAuthorized
) => [
  {
    name: 'response',
    label: 'Response',
    selector: (row) => {
      switch (row.actionType) {
        case 'Basic Response':
          return row.actionData?.response ?? '<No Response>'
        case 'Fetch':
          return '<Fetch>'
        case 'Counter':
          return `<Counter: ${row.actionData?.counter ?? 'No Counter'}>`
        case 'Script':
          return `<Script>`
      }
    },
    rawSelector: 'response',
    type: 'text',
    // width: 200,
    style: { textAlign: 'left' },
  },
  {
    name: 'edit',
    label: null,
    selector: (row) => {
      if (!isAuthorized) {
        return null
      }
      if (type === 'Command' && row.admin_command && !isAdmin) {
        return null
      }

      if (type === 'Interval' && row.admin_interval && !isAdmin) {
        return null
      }
      return (
        <img
          className="image-button"
          onClick={() => onEdit(row, type)}
          style={{ height: '24px' }}
          src={editIcon}
        />
      )
    },
    rawSelector: 'response',
    type: 'text',
    width: 48,
    style: { textAlign: 'center' },
  },
  {
    name: 'delete',
    label: null,
    selector: (row) => {
      if (!isAuthorized) {
        return null
      }
      if (type === 'Command' && row.admin_command && !isAdmin) {
        return null
      }

      if (type === 'Interval' && row.admin_interval && !isAdmin) {
        return null
      }
      return (
        <img
          className="image-button"
          onClick={() => onDelete(channelName, type, row.id)}
          style={{ height: '24px' }}
          src={deleteIcon}
        />
      )
    },
    rawSelector: 'response',
    type: 'text',
    style: { textAlign: 'center' },
    width: 48,
  },
]

const CommandTableSpec = (
  channelName,
  onEdit,
  onDelete,
  refresh,
  isAdmin,
  isAuthorized
) => [
  ...CommonTableSpecStart,
  {
    name: 'pattern',
    label: 'Pattern',
    selector: 'pattern',
    type: 'text',
    width: 200,
    style: { textAlign: 'left' },
  },
  ...CommonTableSpecEnd(
    channelName,
    onEdit,
    onDelete,
    'Command',
    isAdmin,
    isAuthorized
  ),
]

const IntervalTableSpec = (
  channelName,
  onEdit,
  onDelete,
  refresh,
  isAdmin,
  isAuthorized
) => [
  ...CommonTableSpecStart,
  {
    name: 'interval',
    label: 'Interval',
    selector: 'interval',
    type: 'number',
    width: 200,
    style: { textAlign: 'left' },
  },
  ...CommonTableSpecEnd(
    channelName,
    onEdit,
    onDelete,
    'Interval',
    isAdmin,
    isAuthorized
  ),
]

const CounterTableSpec = (
  channelName,
  onEdit,
  onDelete,
  refresh,
  isAuthorized
) => [
  {
    name: 'name',
    label: 'Name',
    selector: 'name',
    type: 'text',
    width: 200,
    style: { textAlign: 'left' },
  },
  {
    name: 'key',
    label: 'Key',
    selector: 'key',
    type: 'number',
    width: 200,
    style: { textAlign: 'left' },
  },
  {
    name: 'value',
    label: 'Value',
    selector: 'value',
    type: 'number',
    style: { textAlign: 'left' },
  },
  {
    name: 'edit',
    label: null,
    selector: (row) => {
      if (!isAuthorized) {
        return null
      }
      return (
        <img
          className="image-button"
          onClick={() => onEdit(row, 'Counter')}
          style={{ height: '24px' }}
          src={editIcon}
        />
      )
    },
    rawSelector: 'response',
    type: 'text',
    width: 48,
    style: { textAlign: 'center' },
  },
  {
    name: 'delete',
    label: null,
    selector: (row) => {
      if (!isAuthorized) {
        return null
      }
      return (
        <img
          className="image-button"
          onClick={() => onDelete(channelName, 'Counter', row.id)}
          style={{ height: '24px' }}
          src={deleteIcon}
        />
      )
    },
    rawSelector: 'response',
    type: 'text',
    style: { textAlign: 'center' },
    width: 48,
  },
]

const LoyaltyTableSpec = (channelName, onEdit, refresh, isAuthorized) => [
  {
    name: 'name',
    label: 'Name',
    selector: 'name',
    type: 'text',
    width: 200,
    style: { textAlign: 'left' },
  },
  {
    name: 'amount',
    label: 'Amount',
    selector: 'amount',
    type: 'number',
    style: { textAlign: 'left' },
  },
  {
    name: 'edit',
    label: null,
    selector: (row) => {
      if (!isAuthorized) {
        return null
      }
      return (
        <img
          className="image-button"
          onClick={() => onEdit(row, 'Loyalty')}
          style={{ height: '24px' }}
          src={editIcon}
        />
      )
    },
    rawSelector: 'response',
    type: 'text',
    width: 48,
    style: { textAlign: 'center' },
  },
]

const AuthorizedTableSpec = (channelName, onEdit, onDelete, isAuthorized) => [
  {
    name: 'name',
    label: 'Name',
    selector: () => channelName,
    rawSelector: 'name',
    type: 'text',
    width: 200,
    style: { textAlign: 'left' },
  },
  {
    name: 'user',
    label: 'User',
    selector: 'user',
    type: 'text',
    style: { textAlign: 'left' },
  },
  {
    name: 'edit',
    label: null,
    selector: (row) => {
      if (!isAuthorized) {
        return null
      }
      return (
        <img
          className="image-button"
          onClick={() => onEdit(row)}
          style={{ height: '24px' }}
          src={editIcon}
        />
      )
    },
    rawSelector: 'response',
    type: 'text',
    width: 48,
    style: { textAlign: 'center' },
  },
  {
    name: 'delete',
    label: null,
    selector: (row) => {
      if (!isAuthorized) {
        return null
      }
      return (
        <img
          className="image-button"
          onClick={() => onDelete(channelName, row.id)}
          style={{ height: '24px' }}
          src={deleteIcon}
        />
      )
    },
    rawSelector: 'response',
    type: 'text',
    style: { textAlign: 'center' },
    width: 48,
  },
]

const sampleData = [
  {
    id: 1,
    name: 'Scotts a cunt',
    pattern: '!cunt',
    actionType: 'Basic Response',
    actionData: { response: 'Scott is massive gaping cunt' },
  },
  {
    id: 2,
    name: 'Testing',
    pattern: '!coom',
    actionType: 'Fetch',
    actionData: { url: 'https://a.b.c' },
  },
  {
    id: 3,
    name: 'Testing1',
    pattern: '!coom2',
    actionType: 'Counter',
    actionData: { counter: 'c.count' },
  },
  {
    id: 4,
    name: 'Testing2',
    pattern: '!coom2',
    actionType: 'Script',
    actionData: { script: "console.log('ree')" },
  },
]

const interval = [
  {
    id: 1,
    name: 'Fuck Scott',
    intervalType: 'time',
    interval: 30,
    actionType: 'Basic Response',
    actionData: { response: 'Fuck Scott' },
  },
]

const DEFAULT_LOYALTY = [
  {
    name: 'Normal',
    amount: 100,
  },
  {
    name: 'Active',
    amount: 50,
  },
  {
    name: 'Followers',
    amount: 50,
  },
  {
    name: 'Subscribers',
    amount: 100,
  },
  {
    name: 'VIP',
    amount: 200,
  },
  {
    id: 6,
    name: 'Moderators',
    amount: 500,
  },
]

const fetchChannelData = (channelName) =>
  axios(`/api/${channelName}`).then((resp) => resp.data)

const save = (channelName, type, payload) =>
  axios({
    url: `/api/${channelName}/${type}${payload?.id ? `/${payload.id}` : ''}`,
    method: 'POST',
    data: payload,
  })

const saveCounter = (channelName, payload) =>
  axios({
    url: `/api/${channelName}/counter${payload?.id ? `/${payload.id}` : ''}`,
    method: 'POST',
    data: payload,
  })

const saveAuthorizedUsers = (channelName, payload) =>
  axios({
    url: `/api/${channelName}/authorized-users${
      payload?.id ? `/${payload.id}` : ''
    }`,
    method: 'POST',
    data: payload,
  })

const deleteAuthorizedUser = (channelName, id) =>
  axios({ url: `/api/${channelName}/authorized-users/${id}`, method: 'DELETE' })

const saveInterval = (channelName, payload) =>
  axios({
    url: `/api/${channelName}/interval${payload?.id ? `/${payload.id}` : ''}`,
    method: 'POST',
    data: payload,
  })

const deleteAction = (channelName, type, id) =>
  axios({ url: `/api/${channelName}/${type}/${id}`, method: 'DELETE' })

const deleteCounter = (channelName, _, id) =>
  axios({ url: `/api/${channelName}/counter/${id}`, method: 'DELETE' })

const deleteInteral = (channelName, _, id) =>
  axios({ url: `/api/${channelName}/interval/${id}`, method: 'DELETE' })

const getIdentity = () =>
  axios({ url: '/api/identify', headers: { client: 'api' } }).then(
    (resp) => resp.data,
    (error) => {
      if (error.statusCode === 401 || error.response.status === 401) {
        window.location.href = '/auth/twitch'
      }
    }
  )

function App() {
  const [channelData, setChannelData] = useState(null)
  const [editingAction, setEditingAction] = useState(null)
  const [editingCounter, setEditingCounter] = useState(null)
  const [editingInterval, setEditingInterval] = useState(null)
  const [editingAuthorizedUser, setEditingAuthorizedUser] = useState(null)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showEditCounterModal, setShowEditCounterModal] = useState(false)
  const [showEditIntervalModal, setShowEditIntervalModal] = useState(false)
  const [
    showEditAuthorizedUsersModal,
    setShowEditAuthorizedUsersModal,
  ] = useState(false)
  const [scriptType, setScriptType] = useState('Command')
  const [channelName, setChannelName] = useState('')
  const [userName, setUserName] = useState('')
  const [isAdmin, setIsAdmin] = useState(false)
  const [authorizedChannels, setAuthorizedChannels] = useState([])

  const refresh = () => fetchChannelData(channelName).then(setChannelData)

  useEffect(
    () =>
      getIdentity().then((resp) => {
        setChannelName(resp?.display_name ?? '')
        setUserName(resp?.name ?? '')
        setIsAdmin(resp?.is_admin ?? false)
        setAuthorizedChannels(
          (resp?.authorizedChannels ?? []).map((channel) =>
            channel.toLowerCase()
          )
        )
      }),
    []
  )

  useEffect(() => {
    refresh()
  }, [channelName])

  const commands = channelData?.commands ?? []
  const counters = channelData?.counters ?? []
  const intervals = channelData?.intervals ?? []
  const loyalty = channelData?.loyalty ?? DEFAULT_LOYALTY
  const authorizedUsers = channelData?.authorizedUsers ?? []
  const isAuthorized =
    channelName.toLowerCase() === userName.toLowerCase() ||
    authorizedChannels.includes(channelName.toLowerCase()) ||
    isAdmin

  const onEditAction = (action, type) => {
    setScriptType(type)
    setEditingAction(action)
    setShowEditModal(true)
  }

  const onDeleteAction = async (...args) => {
    await deleteAction(...args)
    refresh()
  }

  const onEditCounter = (action) => {
    setEditingCounter(action)
    setShowEditCounterModal(true)
  }

  const onEditAuthorizedUser = (authorizedUser) => {
    console.log(authorizedUser)
    setEditingAuthorizedUser(authorizedUser)
    setShowEditAuthorizedUsersModal(true)
  }

  const onDeleteCounter = async (...args) => {
    await deleteCounter(...args)
    refresh()
  }

  const onEditInterval = (action) => {
    setEditingInterval(action)
    setShowEditIntervalModal(true)
  }

  const onDeleteInterval = async (...args) => {
    await deleteInteral(...args)
    refresh()
  }

  const onDeleteAuthorizedUser = async (...args) => {
    await deleteAuthorizedUser(...args)
    refresh()
  }

  useEffect(() => {
    setChannelData(null)
  }, [channelName, setChannelData])

  return (
    <div className="App">
      <input
        onChange={(e) => setChannelName(e.target.value)}
        value={channelName}
      ></input>
      <button onClick={refresh}>Load</button>
      {channelData != null ? (
        <>
          <h2>{channelName}</h2>
          <h2>Commands</h2>
          <Table
            data={commands}
            spec={CommandTableSpec(
              channelName,
              onEditAction,
              onDeleteAction,
              refresh,
              isAdmin,
              isAuthorized
            )}
          />
          <Button
            className={styles.button}
            onClick={() => {
              setScriptType('Command')
              setEditingAction({})
              setShowEditModal(true)
            }}
            label="Create"
          ></Button>
          <h2>Counters</h2>
          <Table
            data={counters}
            spec={CounterTableSpec(
              channelName,
              onEditCounter,
              onDeleteCounter,
              refresh,
              isAuthorized
            )}
          />
          <Button
            className={styles.button}
            onClick={() => {
              setEditingCounter({})
              setShowEditCounterModal(true)
            }}
            label="Create"
          ></Button>
          <h2>Intervals</h2>
          <Table
            data={intervals}
            spec={IntervalTableSpec(
              channelName,
              onEditInterval,
              onDeleteInterval,
              refresh,
              isAdmin,
              isAuthorized
            )}
          />
          <Button
            className={styles.button}
            onClick={() => {
              setEditingInterval({})
              setShowEditIntervalModal(true)
            }}
            label="Create"
          ></Button>
          <h2>Loyalty</h2>
          <Table
            data={loyalty}
            spec={LoyaltyTableSpec(
              channelName,
              onEditAction,
              refresh,
              isAuthorized
            )}
          />
          <h2>Authorized Users</h2>
          <Table
            data={authorizedUsers}
            spec={AuthorizedTableSpec(
              channelName,
              onEditAuthorizedUser,
              onDeleteAuthorizedUser,
              isAuthorized
            )}
          />
          <Button
            className={styles.button}
            onClick={() => {
              setEditingAuthorizedUser({})
              setShowEditAuthorizedUsersModal(true)
            }}
            label="Create"
          ></Button>
          <ScriptModal
            isAdmin={isAdmin}
            scriptType={scriptType}
            script={editingAction}
            visible={showEditModal}
            onChange={async (data) => {
              await save(channelName, scriptType, data)
              setShowEditModal(false)
              refresh()
            }}
            onHide={() => setShowEditModal(false)}
          />
          <CounterModal
            counter={editingCounter}
            visible={showEditCounterModal}
            onChange={async (data) => {
              await saveCounter(channelName, data)
              setShowEditCounterModal(false)
              refresh()
            }}
            onHide={() => setShowEditCounterModal(false)}
          />
          <IntervalModal
            isAdmin={isAdmin}
            interval={editingInterval}
            visible={showEditIntervalModal}
            onChange={async (data) => {
              await saveInterval(channelName, data)
              setShowEditIntervalModal(false)
              refresh()
            }}
            onHide={() => setShowEditIntervalModal(false)}
          />
          <AuthorizedUsersModal
            authorizedUser={editingAuthorizedUser}
            visible={showEditAuthorizedUsersModal}
            onChange={async (data) => {
              await saveAuthorizedUsers(channelName, data)
              setShowEditAuthorizedUsersModal(false)
              refresh()
            }}
            onHide={() => setShowEditAuthorizedUsersModal(false)}
          />
        </>
      ) : null}
    </div>
  )
}

export default App
