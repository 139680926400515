import React from 'react'

import styles from './Modal.module.scss'

const ModalHeader = ({ header, onHide }) => {
  return (
    <div className={styles.modalHeader}>
      <div className={styles.headerContent}>{header}</div>
      <div className={styles.headerCloseButton} onClick={onHide}>
        x
      </div>
    </div>
  )
}

export default ModalHeader
