import React from 'react'
import styles from './Button.module.scss'
import cn from 'classnames'

const Button = ({ className, label, onClick, style = 'default' }) => {
  console.log(styles, style)
  return (
    <div
      className={cn(styles.button, styles[style], className)}
      onClick={onClick}
    >
      {label}
    </div>
  )
}

export default Button
