const replace = (o, key, value) => {
  if (Array.isArray(o)) {
    return [...o.slice(0, key), value, ...o.slice(key + 1)]
  }

  return {
    ...o,
    [key]: value,
  }
}

export default replace
