import React, { useRef, useEffect, useContext } from 'react'
import styles from './Modal.module.scss'
import { cx as cn } from '@emotion/css'
import Loader from '../Loader/Loader'
import ModalHeader from './ModalHeader'
import ModalFooter from './ModalFooter'

const ModalContext = React.createContext({
  depth: 1,
})

const Modal = ({
  children,
  height = 'auto',
  width = 'auto',
  onHide,
  visible,
  header,
  footer,
}) => {
  const style = { height, width }
  const modalRef = useRef(null)
  const { depth } = useContext(ModalContext)
  const innerCaptureRef = useRef(null)

  const zIndex = 10 * depth

  return (
    <div
      ref={modalRef}
      style={{ zIndex }}
      className={cn(styles.modal, { [styles.visible]: visible })}
      onClickCapture={(e) => {
        if (e.target === modalRef.current && !innerCaptureRef.current) {
          innerCaptureRef.current = false
          onHide()
        }
        innerCaptureRef.current = false
      }}
    >
      <React.Suspense fallback={<Loader />}>
        <ModalContext.Provider value={{ depth: depth + 1 }}>
          <div
            className={styles.modalContents}
            onMouseDown={(e) => (innerCaptureRef.current = true)}
            style={style}
          >
            {header ? <ModalHeader header={header} onHide={onHide} /> : null}
            <div className={styles.modalBody}>{children}</div>
            {footer ? <ModalFooter footer={footer} /> : null}
          </div>
        </ModalContext.Provider>
      </React.Suspense>
    </div>
  )
}

export default Modal
