import React, { useState, useEffect } from 'react'
import { get } from 'lodash'
import styles from './Table.module.scss'
import emptyFunction from './utils/emptyFunction'
import replace from './utils/replace'

const getData = (row, selector) => {
  return typeof selector === 'function' ? selector(row) : get(row, selector)
}

const EditableColumn = ({
  label,
  name,
  onChange,
  options = [],
  type,
  value,
}) => {
  switch (type) {
    case 'boolean':
      return (
        <span style={styles.checkbox}>
          <input
            type="checkbox"
            onChange={(e) => onChange(e.target.checked)}
            checked={value ?? false}
          />
        </span>
      )
    case 'number':
      return (
        <input
          type="number"
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      )
    case 'text':
      return (
        <input
          type="text"
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      )

    case 'enum':
      return (
        <select value={value} onChange={(e) => onChange(e.target.value)}>
          <option></option>
          {options.map((v) => (
            <option key={v.value} value={v.value}>
              {v.label}
            </option>
          ))}
        </select>
      )
    default:
      throw new Error('Unsupported Column Type')
  }
}

const UneditableColumn = ({ column, row }) => {
  const data = getData(row, column.selector)

  const title = `${
    column.rawSelector ? getData(row, column.rawSelector) : data
  }`

  return (
    <div className={styles.column} style={column.style} title={title}>
      {data}
    </div>
  )
}

const Row = ({ editable, onChange, spec = [], data }) => {
  return (
    <div
      className={styles.row}
      style={{ display: 'contents', background: 'orange' }}
    >
      {spec.map((column) => {
        const { label, name, options, type, defaultValue } = column
        const val = data.hasOwnProperty(name) ? data[name] : defaultValue
        return editable ? (
          <EditableColumn
            key={name}
            onChange={(value) => {
              onChange(replace(data, name, value))
            }}
            label={label}
            name={name}
            options={options}
            type={type}
            value={val}
          />
        ) : (
          <UneditableColumn key={name} column={column} row={data} />
        )
      })}
    </div>
  )
}

const Table = ({
  disableQuery,
  disableSorting,
  editable = false,
  data,
  spec,
  pageSize = 250,
  query = {},
  onQueryChange = emptyFunction,
  onOrderByChange,
  onChange,
}) => {
  const [offset, setOffset] = useState(0)

  const [orderBy, _setOrderBy] = useState(null)

  const setOrderBy = (order) => {
    _setOrderBy(order)
    if (onOrderByChange) onOrderByChange(order)
  }

  useEffect(() => {
    setOffset(0)
  }, [data])

  if (data === null) {
    return null
  }

  const toRender =
    editable || pageSize <= 0 ? data : data.slice(offset, pageSize)

  const headers = spec.map((column) => {
    return (
      <div
        key={column.name}
        onClick={
          disableSorting
            ? null
            : () => {
                if (orderBy === `${column.name}_DESC`) {
                  setOrderBy(null)
                } else if (orderBy === column.name) {
                  setOrderBy(`${column.name}_DESC`)
                } else {
                  setOrderBy(column.name)
                }
              }
        }
      >
        {column.label}
      </div>
    )
  })

  const elements = toRender.map((row, rowIndex) => (
    <Row
      key={rowIndex}
      editable={editable}
      onChange={(value) => onChange(replace(data, rowIndex, value))}
      spec={spec}
      data={row}
    />
  ))

  // const elements = toRender.map((row, rowIndex) => {
  //   return spec.map((column) => {
  //     const key = `${column.name}-${rowIndex + offset}`

  //     const data = getData(row, column.selector)

  //     const title = `${
  //       column.rawSelector ? getData(row, column.rawSelector) : data
  //     }`

  //     return (
  //       <div
  //         className={styles.column}
  //         key={key}
  //         style={column.style}
  //         title={title}
  //       >
  //         {data}
  //       </div>
  //     )
  //   })
  // })

  const columnTemplate = spec
    .map((column) => (column.width ? column.width + 'px' : '1fr'))
    .join(' ')

  return (
    <div
      className={styles.table}
      style={{ gridTemplateColumns: columnTemplate }}
    >
      {/* {inputs} */}
      {headers}
      {elements}
    </div>
  )
}

export default Table
