import React, { useState, useEffect } from 'react'

const Loader = () => {
  const [tick, setTick] = useState(0)

  useEffect(() => {
    const to = setTimeout(() => setTick((tick) => (tick + 1) % 4), 50)

    return () => clearTimeout(to)
  }, [setTick, tick])

  return <div>Loading{''.padEnd(tick, '.')}</div>
}

export default Loader
